import React, { useEffect, useState } from 'react'
import axios from "axios"
import api from "../config/backend"
import "./Chatbot.css"
import { RiExternalLinkLine } from "react-icons/ri"

const RenderImage = ({ state, setState }) => {
    const imagelocation = state.payload && state.payload.location
    const transfer = state.payload && state.payload.action

    const domain = api.replace("/api", "")

    const link = domain + imagelocation
    
    return (
        <div className={transfer === "sent" ? "_6ftp sent" : "_6ftp"}>
            <div className="container" onClick={() => window.open(link, "_blank")}>
                <img src={link} alt="" />
                <div className="devilkin-gem">
                    <RiExternalLinkLine />
                </div>
            </div>
        </div>
    )
}

export default RenderImage
