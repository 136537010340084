import React, { useContext, useEffect, useState } from 'react'
import "./Chatbot.css"
import { createClientMessage } from "react-chatbot-kit"
import axios from 'axios'
import api from "../config/backend"
import { DashboardContext } from "../pages/Home"

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
    const [message, setMessage] = useState({ data: undefined })
    const [prevChatLength, setPrevChatLength] = useState(0)
    const [chatLength, setChatLength] = useState(0)
    const [chatCleared, setChatCleared] = useState(false)
    const [chat, setChat] = useState([])

    const { state, action } = useContext(DashboardContext)

    const token = localStorage.getItem("ca-token")

    const handleMessage = (data) => {
        const botMessage = createChatBotMessage(data.message)

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }))
    }

    const handleWidget = (data) => {
        let reply
        let widgetdata

        if (data.widget === "options") {
            reply = createChatBotMessage(
                data.message,
                {
                    widget: 'optionSelect'
                }
            )

            widgetdata = data.options
        }
        else {
            reply = createChatBotMessage(data.message)
        }

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, reply],
            widgetdata
        }))
    }

    const handleReceived = async (prevlength) => {
        const { data } = await axios.post(`${api}/Agent/fetch-messages`, { token })

        if (data.success) {
            // Assign messages to bot and user
            const parsed = data.messages.map(message => {
                if (message.from === "author") {
                    // Add image as a widget
                    if (message.kind === "image") {
                        return createChatBotMessage("» Received an image", {
                            payload: { location: `/assets/${message.message}`, action: "received" },
                            widget: 'renderImage'
                        })
                    }
                    return createChatBotMessage(message.message)
                }
                else {
                    if (message.kind === "image") {
                        return createClientMessage("» Sent an image", {
                            payload: { location: `/uploads/${message.message}`, action: "sent" },
                            widget: 'renderImage'
                        })
                    }
                    return createClientMessage(message.message)
                }
            })

            // Slice out previous messages i.e. existing on chat
            const newMessages = parsed.slice(prevlength,)

            let filtered = newMessages

            // Unless the page is refreshed
            // Remove the bot messages that came from backend
            //      - bot messages are added on frontend chatbotkit when agent sends message
            if (prevChatLength !== 0) {
                filtered = newMessages.filter(item => item.type === "bot")
            }

            setPrevChatLength(chatLength)
    
            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, ...filtered]
            }))
        }
    }

    // This function runs every one second
    // It compares state "chatLength" with received messages count
    //      - If they're different i.e. we have new message from client
    //      - Update chat length, this will fire up "handleReceived" function
    // Warning: don't directly save chats in state otherwise chat will keep refreshing
    const checkUpdates = async () => {
        const { data } = await axios.post(`${api}/Agent/fetch-messages`, { token })

        if (data.success) {
            if (chatLength !== data.messages.length) {
                setChatLength(data.messages.length)
                action.setMessage("Client connected")
            }
        }
        // Either client just connected
        // Or the chat has been disconnected and no client is assigned to agent
        else {
            action.setMessage(data.message)
            setChatCleared(true)

            // Client just connected
            // Fetch client's information
            if (data.message === "Waiting for client to initiate a conversation") {
                state.refreshClient === 0 && action.setRefreshClient(1)
            }
            else if (data.message === "Please wait – looking for whatsapp client") {
                action.setRefreshClient(0)
            }
        }
    }

	useEffect(() => {
        let interval

        // Subscribe to message events
        if (state.clientAssigned) {
            interval = setInterval(() => {
                checkUpdates()
            }, 1000)
        }
        
        return () => clearInterval(interval)
	}, [state.clientAssigned])

	useEffect(() => {
        if (chatLength && chatCleared) {
            setState((prev) => ({
                ...prev,
                messages: []
            }))
            setChatLength(0)
            setChatCleared(false)
        }

        if (prevChatLength !== chatLength) {
            handleReceived(prevChatLength)
        }
	}, [chatLength, chatCleared])

    console.log(1)

    return (
        <div className="_8ism">
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    actions: {
                        handleMessage, handleWidget
                    },
                })
            })}
        </div>
    )
}

export default ActionProvider