import Chatbot from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css'
import config from '../chatbot/config'
import MessageParser from "../chatbot/MessageParser"
import ActionProvider from "../chatbot/ActionProvider"
import { useState, useEffect, createContext } from 'react'
import axios from "axios"
import api from "../config/backend"
import { BiSolidImageAlt } from "react-icons/bi"
import "./css/Home.css"
import Header from '../components/Header'

export const DashboardContext = createContext()

// This component needs domain and token in local storage
const Home = () => {
    const [chatopen, setChatOpen] = useState(true)
    const [isAvailable, setIsAvailable] = useState(false)
    const [message, setMessage] = useState("")
    const [refreshClient, setRefreshClient] = useState(0)
    const [clientAssigned, setClientAssigned] = useState(undefined)
    const [clientInformation, setClientInformation] = useState(undefined)
    const [agentDetails, setAgentDetails] = useState(undefined)

    // Notification should be in format
    //      - green: { error: false, message: "This is a success notification" }
    //      - red: { error: true, message: "This is a error notification" }
    const [notification, setNotification] = useState(undefined)

    // Popup to accept or reject incoming client support request
    const [incomingRequest, setIncomingRequest] = useState(undefined)

    const value = {
        state: { message, notification, refreshClient, clientAssigned },
        action: { setMessage, setNotification, setRefreshClient, setClientAssigned }
    }

    const token = localStorage.getItem("ca-token")

    const agentInfo = async () => {
        const { data, status } = await axios.post(`${api}/Agent/info`, { token })

        if (data.success && data.agent) {
            setIsAvailable(data.agent.available)
            setAgentDetails(data.agent)

            if (data.agent.client) {
                setClientAssigned(data.agent.client)
            }
        }
    }

    // status can be boolean true or false
    const toggleAvailability = async () => {
        const { data } = await axios.post(`${api}/Agent/toggle-available`, { token, status: !isAvailable })

        if (data.success) {
            setIsAvailable(data.agent.available)
        }
        else {
            setNotification({ error: true, message: data.message })
        }
    }

    const disconnectChat = async (req, res) => {
        const { data } = await axios.post(`${api}/Agent/disconnect-chat`, { token })

        if (data.success) {
            setNotification({ error: false, message: data.message })
            setClientAssigned(undefined)
        }
        else {
            setNotification({ error: true, message: data.message })
        }
    }

    // Agent last seen
    const onlinePing = async () => {
        const { data } = await axios.post(`${api}/Agent/keep-alive`, { token })
    }

    // Handle file upload
    const handleImage = async (event) => {
        const data = new FormData()

        const file = event.target.files[0]

        const validfiles = ["image/jpeg", "image/png"]

        if (file) {
            if (validfiles.includes(file.type) === false) {
                setNotification({ error: true, message: "Please select an image" })
                return
            }

            setNotification({ error: false, message: "Please wait... uploading in progress" })

            data.append('file', file)
            data.append('token', token)

            const response = await axios.post(`${api}/Agent/send-image`, data)

            console.log({ response })
        }
    }

    const fetchClientInformation = async () => {
        const { data, status } = await axios.post(`${api}/Agent/client-info`, { token })

        if (data.success) {
            setClientInformation(data.client[0])
        }
    }

    const checkNotification = async () => {
        const { data, status } = await axios.post(`${api}/Agent/get-notification`, { token })

        if (data.success) {
            if (data.notification) {
                setIncomingRequest(data.notification)
            }
            else {
                setIncomingRequest(undefined)
            }
        }
    }

    const notificationAction = async (accept) => {
        const { data, status } = await axios.post(`${api}/Agent/accept-notification`, { token, hash: incomingRequest.hash, accept })

        if (data.success && accept) {
            setClientAssigned(incomingRequest.client)
            setIncomingRequest(undefined)
        }
    }

    useEffect(() => {
        agentInfo()
        onlinePing()

        // Keep agent online i.e. update last seen
        //      - one minute interval
        const interval = setInterval(() => {
            onlinePing()
        }, 60000)

        return () => clearInterval(interval)
    }, [])

    // Get client information when "refreshClient" updates
    useEffect(() => {
        if (refreshClient) {
            agentInfo()
        }
        else {
            setClientAssigned(undefined)
        }
    }, [refreshClient])

    useEffect(() => {
        console.log({ clientAssigned })

        if (clientAssigned) {
            fetchClientInformation()
        }

        let interval

        if (isAvailable && clientAssigned === undefined) {
            // Subscribe to agent notifications
            interval = setInterval(() => {
                checkNotification()
            }, 1000)
        }

        return () => clearInterval(interval)
    }, [clientAssigned, isAvailable])

    // Hide notification
    useEffect(() => {
        const timeout = setTimeout(() => {
            setNotification(undefined)
        }, 3000)

        return () => clearTimeout(timeout)
    }, [notification])

    return (
        <DashboardContext.Provider value={value}>
            <Header agentDetails={agentDetails} isOnline={isAvailable} />
            <div className="_4noz">
                {notification ? (
                    <div className={notification.error ? "comakers-gang error" : "comakers-gang"}>
                        {notification.message}
                    </div>
                ) : undefined}
                {incomingRequest ? <div className="scryers-cure">
                    <div className="pinnate-oft">
                        <div className="buffeted-yens">Incoming request</div>
                        <div className="unwedge-zed">Client <span>({incomingRequest.client})</span> wants to connect </div>
                        <div className="avenges-lux">
                            <button onClick={() => notificationAction(true)}>Accept</button>
                            <button onClick={() => notificationAction(false)}>Reject</button>
                        </div>
                    </div>
                </div> : undefined}
                <div className="candours-top">
                    <div>
                        {clientAssigned && clientInformation ? (
                            <div className="casked-loo">
                                <div className="fullname">{clientInformation.fname + " " + clientInformation.lname}</div>
                                <div className="reave-wean">Phone: {clientAssigned}</div>
                                <div className="reave-wean">E-mail: {clientInformation.AdminLogin && clientInformation.AdminLogin.email}</div>
                                <div className="reave-wean">Location: {clientInformation.api && clientInformation.api.name}</div>
                            </div>
                        ) : (
                            <div style={{ fontSize: "14px", color: "grey" }}>
                                Not connected to client
                            </div>
                        )}

                    </div>
                </div>
                <div className="cutback-nub">
                    <div className="ironage-goer">
                        Message: {message}
                        <div className="smoother-coy">
                            {clientAssigned ? <button onClick={() => disconnectChat()}>Disconnect client</button> : (
                                <button
                                    onClick={() => toggleAvailability()}
                                >
                                    Go {isAvailable ? "offline" : "online"}
                                </button>
                            )}
                        </div>
                    </div>
                    <div style={{ zIndex: "2", display: "flex", flexDirection: "column", alignItems: "end", gap: "10px" }}>
                        {chatopen ? <div className="pains-gnat">
                            <Chatbot
                                config={config}
                                messageParser={MessageParser}
                                actionProvider={ActionProvider}
                            />
                            <div className="escapers-loon">
                                <input
                                    type="file"
                                    id="imageupload"
                                    name="imageupload"
                                    accept=".jpeg, .png, .jpg"
                                    onChange={e => handleImage(e)}
                                />
                                <label htmlFor="imageupload">
                                    <BiSolidImageAlt />
                                </label>
                            </div>
                        </div> : undefined}
                    </div>
                </div>
            </div>
        </DashboardContext.Provider>
    )
}

export default Home
