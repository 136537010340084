import React, { useEffect, useState } from 'react'
import { createChatBotMessage, createClientMessage } from "react-chatbot-kit"
import axios from "axios"
import api from "../config/backend"

// options - [{identifier: "{unique number}", value: "{option message to display to user}"}, {...}, {...}]
const Options = ({ state, setState }) => {
    const [isloading, setIsLoading] = useState(false)
    const [loadingIndex, setLoadingIndex] = useState(undefined)

    const options = state.widgetdata

    // console.log({ endpoint })

    useEffect(() => {
        const init = async () => {
            const { data, status } = await axios.post(`${api}/Static/get-response`, {
                message: options[loadingIndex]
            })

            console.log({ data })

            let reply
            let widgetdata

            setIsLoading(false)
            setLoadingIndex(undefined)

            if (data.success) {
                if (data.widget === "options") {
                    reply = createChatBotMessage(
                        data.message,
                        {
                            widget: 'optionSelect'
                        }
                    )

                    widgetdata = data.options
                } 
                else {
                    reply = createChatBotMessage(data.message)
                }
            } else {
                reply = createChatBotMessage("Something went wrong")
            }

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, reply],
                widgetdata
            }))
        }
        if (isloading) {
            init()
        }
    }, [loadingIndex])
    
    return (
        <div className="_1bwj">
            <div className="container">
                {Array.isArray(options) ? options.map((text, index) => {
                    return <button onClick={() => {
                        const client = createClientMessage(text.value)
                        setState(prev => {
                            return {
                                ...prev,
                                messages: [...prev.messages, client]
                            }
                        })
                        setIsLoading(true)
                        setLoadingIndex(index)
                    }}>{text.value}</button>
                }) : undefined}
            </div>
        </div>
    )
}

export default Options
