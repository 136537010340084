import React, { useEffect, useState } from 'react'
import axios from 'axios'
import api from "../config/backend"

const MessageParser = ({ children, actions }) => {
	const token = localStorage.getItem("ca-token")

	const parse = async (message) => {
		const { data, status } = await axios.post(`${api}/Agent/send-message`, { token, message })

		console.log({ data })

		if (data.success === false) {
			console.log({ error: data.message })
		}
	}

	return (
		<div>
			{React.Children.map(children, (child) => {
				return React.cloneElement(child, {
					parse: parse,
					actions: {},
				})
			})}
		</div>
	)
}

export default MessageParser