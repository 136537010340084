import { Link, Outlet } from "react-router-dom"

const LandingPage = () => {
    return (
        <div>
            <div style={{ display: "flex", gap: "10px", padding: "20px" }}>
                <Link to="/sign-in">Login</Link>
                <Link to="/sign-up">Register</Link>
            </div>
            <Outlet />
        </div>
    )
}

export default LandingPage
